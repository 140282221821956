<template>
  <v-card class='pa-5'>
    <v-card-text class='d-flex flex-column align-center pa-0 mb-3'>
      <v-card-title class='pa-0 mb-1'>{{ sections.donation || 'Donation title' }}</v-card-title>
      <div class='d-flex align-center'>
        <v-card-subtitle class='pa-0 mr-5'>
          Donation Amount
          <span class='font-weight-bold ml-2'>
            ${{ allocationTotal }}
          </span>
        </v-card-subtitle>
        <v-btn
          :to='{name: routeName}'
          class='text-capitalize font-weight-bold'
          color='primary'
          outlined
          small
        >
          Go to Charities
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      :headers='headers'
      :items='statistics.charities'
      :loading='loading'
      hide-default-footer
      loading-text='Loading donations ...'
    >
      <template #item.percentage='{ item }'>
        {{ item.percentage }}%
      </template>
      <template #item.allocation='{ item }'>
        ${{ item.allocation }}
      </template>
      <template #body.append>
        <tr>
          <td
            v-for='(item, i) in totals'
            :key='i'
            :class="{'text-center': i !== 0}"
            :colspan='1'
            class='font-weight-bold'
          >
            {{ item }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'donation-statistics',
  props: {
    statistics: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: '',
          sortable: false,
          value: 'charity',
          divider: true,
          width: '35%'
        },
        {
          text: 'Emp. Donations',
          align: 'center',
          sortable: false,
          value: 'employeeCount',
          divider: true
        },
        {
          text: '%',
          align: 'center',
          sortable: false,
          value: 'percentage',
          divider: true
        },
        {
          text: '$ Allocation',
          align: 'center',
          sortable: false,
          value: 'allocation'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections
    }),
    ...mapGetters({
      user: 'user'
    }),
    routeName() {
      return this.user.id_rol === 1 ? 'Charity' : 'MemberCharities'
    },
    allocationTotal() {
      return this.statistics?.allocation ? this.statistics.allocation.allocationTotal : 0
    },
    totals() {
      return [
        'Totals',
        this.statistics.employeeTotal,
        `${ this.statistics.percentageTotal }%`,
        `$${ this.allocationTotal }`
      ]
    }
  }
}
</script>
