import { render, staticRenderFns } from "./employee-registration-column.vue?vue&type=template&id=0e67a8e4&scoped=true&"
import script from "./employee-registration-column.vue?vue&type=script&lang=js&"
export * from "./employee-registration-column.vue?vue&type=script&lang=js&"
import style0 from "./employee-registration-column.vue?vue&type=style&index=0&id=0e67a8e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e67a8e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VRow,VSkeletonLoader})
