export default {
  toReportingFilters: (electionName, string) => {
    let register = null
    let voted = null
    if (string.includes('Has Not Voted')) {
      voted = false
    } else if (string.includes('Voted')) {
      voted = true
    }
    if (string.includes('Not Registered')) {
      register = false
    } else if (string.includes('Registered')) {
      register = true
    }
    return {
      reportingFilters: {
        election: electionName,
        register: register,
        voted: voted
      }
    }
  }
}