<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    persistent
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-4'>
        <v-icon
          class='mr-4 white--text'
          color='primary'
          large
        >
          mdi-home-outline
        </v-icon>
        <span>Available Member Information</span>
      </v-card-title>
      <v-card-subtitle>
        <b>
          Select one company from the member companies list below to
          visualize that company's particular statistics:
        </b>
      </v-card-subtitle>
      <v-radio-group
        v-model='memberId'
        class='ml-6'
      >
        <v-radio
          v-for='member in members'
          :key='member.id'
          :label='member.name'
          :value='member.id'
          class='pb-2'
        ></v-radio>
      </v-radio-group>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('close-dialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='onChangeMember'
        >
          Change
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'change-company-dialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    members: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedMemberId: {
      required: true
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) this.memberId = this.selectedMemberId
    }
  },
  data: () => ({
    memberId: null
  }),
  methods: {
    ...mapMutations('adminStore', ['SET_SELECTED_MEMBER']),
    onChangeMember() {
      const memberSelected = this.members.find(member => member.id === this.memberId)
      this.SET_SELECTED_MEMBER(memberSelected)
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>