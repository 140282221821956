<template>
  <v-card class='pa-5 d-flex flex-column justify-center'>
    <v-card-text class='d-flex flex-column align-center pt-0'>
      <v-card-title class='pt-0'>{{ sections.election_cycle || 'Election cycle title' }}</v-card-title>
      <v-card-subtitle class='mr-6 text-capitalize'>
        {{ selectedElection.description }}
      </v-card-subtitle>
    </v-card-text>
    <v-skeleton-loader
      v-if='loadingPieChartData'
      class='my-0 mx-auto rounded-circle'
      height='300'
      type='image'
      v-bind='attrs'
      width='300'
    ></v-skeleton-loader>
    <GChart
      v-else
      v-show='hasStatistics'
      ref='pieChartObject'
      :data='pieChartData'
      :events='pieChartEventListener'
      :options='pieChartOptions'
      class='mx-auto'
      type='PieChart'
    />
    <div
      v-show='!hasStatistics'
      class='py-16'
    >
      <h2 class='secondary--text text-center px-8 py-10'>
        There are no election statistics available for this company or
        voting campaign, try selecting another one.
      </h2>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import memberService from '@/services/member'
import stringConverter from '@/helpers/stringConverter'

export default {
  name: 'voting-record-cycle',
  props: {
    selectedMemberId: {
      default: 'all'
    }
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        elevation: 2
      },
      employeesByVoteStatus: null,
      hasStatistics: true,
      pieChartData: [
        ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
        ['Registered & Voted', 0, ``],
        ['Registered & Has Not Voted', 0, ''],
        ['Not Registered', 0, ''],
        ['Ineligible', 0, '']
      ],
      pieChartOptions: {
        height: 400,
        width: '100%',
        chartArea: {
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 100
        },
        colors: ['mediumblue', 'gold', 'firebrick', 'darkgreen'],
        fontSize: 14,
        fontName: 'roboto',
        pieHole: 0.5,
        legend: {
          position: 'bottom',
          width: '100%',
          alignment: 'center'
        },
        pieSliceText: 'none',
        annotations: {
          style: 'none',
        },
        backgroundColor: {}
      },
      loadingPieChartData: true
    }
  },
  computed: {
    ...mapState({
      sections: state => state.cmsStore.sections,
      selectedElection: state => state.electionStore.selectedElection,
      selectedMember: state => state.adminStore.selectedMember
    }),
    ...mapGetters({
      user: 'user'
    }),
    isAdmin() {
      return this.user.id_rol === 1
    },
    memberId() {
      return this.user.profile_id.member_id
    },
    pieChartEventListener() {
      return {
        'select': () => {
          this.onChartSelectionChanged()
        }
      }
    }
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection.id) await this.getStatistics()
      },
      deep: true
    },
    selectedMember: {
      async handler() {
        if (this.selectedElection.id) await this.getStatistics()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getStatisticsByMember: 'memberStore/getCurrentElectionStatistics',
      getStatisticsByAdmin: 'adminStore/getCurrentElectionStatistics',
      reportingFilters: 'reportingFilters'
    }),
    ...mapMutations({
      changeRoutePush: 'changeRoutePush'
    }),
    async getStatistics() {
      this.loadingPieChartData = true
      this.hasStatistics = true
      const params = {
        electionId: this.selectedElection.id,
        memberId: this.isAdmin ? this.selectedMemberId : this.memberId
      }
      this.votingStatistics = this.isAdmin
        ? await this.getStatisticsByAdmin(params)
        : await this.getStatisticsByMember(params)
      this.setPieChartData(this.votingStatistics)
      this.loadingPieChartData = false
    },
    async onChartSelectionChanged() {
      const selected = this.$refs.pieChartObject.chartObject.getSelection()
      if (selected.length === 0) {
        this.$emit('show-section-employees-info', { show: false, employeesByVoteStatus: [] })
        return
      }
      const headerOffset = 1
      const sectionName = this.pieChartData[selected[0].row + headerOffset][0]
      this.isAdmin
        ? await this.onChartChangedForAdmin(sectionName)
        : await this.onChartChangedForMember(sectionName)
    },
    async onChartChangedForAdmin(sectionName) {
      const res = stringConverter.toReportingFilters(this.selectedElection, sectionName)
      this.changeRoutePush(true)
      await this.reportingFilters(res)
      await this.$router.push({ name: 'ReportingMaps' })
    },
    async onChartChangedForMember(sectionName) {
      this.sectionEmployeesByVoteTitle = `Employees who: ${ sectionName }`
      this.$emit('show-section-employees-info', { show: true, loading: true })
      try {
        const response = await memberService.getEmployeesByVoteStatus({
          memberId: this.memberId,
          electionId: this.selectedElection.id,
          selectedJobType: 'null',
          selectedVoteCategory: sectionName
        })
        if (response.length) {
          this.$emit('show-section-employees-info', {
            loading: false,
            sectionEmployeesByVoteTitle: this.sectionEmployeesByVoteTitle,
            show: true,
            employeesByVoteStatus: response
          })
        } else {
          this.$emit('show-section-employees-info', {
            loading: false,
            sectionEmployeesByVoteTitle: this.sectionEmployeesByVoteTitle,
            show: true,
            employeesByVoteStatus: response
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    setPieChartData(statistics) {
      const pieChartHasData = this.pieChartHasData(statistics)
      if (!pieChartHasData) {
        this.pieChartData = [
          ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
          ['Registered & Voted', 0, ``],
          ['Registered & Has Not Voted', 0, ``],
          ['Not Registered', 0, ``],
          ['Ineligible', 0, ``]
        ]
        this.pieChart = statistics
        this.pieChartData[1][1] = this.pieChart[1][1]  // voted
        this.pieChartData[2][1] = this.pieChart[0][1]  // registrated
        this.pieChartData[3][1] = this.pieChart[2][1]  // not_registrated
        this.pieChartData[4][1] = this.pieChart[3][1]  // inelegible
        const percentageRegisteredAndVoted = this.pieChart[1][1]/(this.pieChart[0][1] + this.pieChart[1][1]) * 100
        const percentageRegisteredAndHasNotVoted = this.pieChart[0][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
        const percentageNotRegistered = this.pieChart[2][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
        const percentageIneligible = this.pieChart[3][1]/(this.pieChart[0][1] + this.pieChart[2][1]  + this.pieChart[3][1]) * 100
        this.pieChartData[1][2] = `Registered & Voted: \n ${this.pieChart[1][1]} (${percentageRegisteredAndVoted.toFixed(2)}%)`
        this.pieChartData[2][2] = `Registered & Has Not Voted: \n ${this.pieChart[0][1]} (${percentageRegisteredAndHasNotVoted.toFixed(2)}%)`
        this.pieChartData[3][2] = `Not Registered: \n ${this.pieChart[2][1]} (${percentageNotRegistered.toFixed(2)}%)`
        this.pieChartData[4][2] = `Ineligible: \n ${this.pieChart[3][1]} (${percentageIneligible.toFixed(2)}%)`
      } else {
        this.hasStatistics = false
      }
    },
    pieChartHasData(statistics) {
      return statistics.every(el => el[1] === 0)
    }
  }
}
</script>

<style lang='scss'>
.rounded-circle {
  .v-skeleton-loader__image {
    height: 300px !important;
    border-radius: 140px !important;
    background: #5b8dd7 !important;
    opacity: 0.7;
  }
}
</style>
