<template>
  <v-card class='pa-5'>
    <v-row class='ma-0 justify-space-between'>
      <h3>{{ sections.diagram || 'Diagram title' }}</h3>
      <v-btn
        :to='{name: navBtn.nav }'
        class='text-capitalize font-weight-bold mr-6'
        color='primary'
        outlined
      >
        {{ navBtn.name }}
      </v-btn>
    </v-row>
    <GChart
      v-if='hasStatistics && !loading'
      ref='columnChartObject'
      :data='columnChartData'
      :events='columnChartEventListener'
      :options='columnChartOptions'
      class='mt-4'
      type='ColumnChart'
    />
    <div
      v-if='loading'
      class='wrap-skeleton-loader my-10'
    >
      <v-skeleton-loader type='paragraph, paragraph, paragraph, paragraph, paragraph'></v-skeleton-loader>
    </div>
    <div
      v-if='!hasStatistics && !loading'
      class='py-16'
    >
      <h2 class='secondary--text text-center'>
        There are no election statistics available for this company or voting
        campaign, try selecting another one.
      </h2>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import stringConverter from '@/helpers/stringConverter'
import memberService from '@/services/member'

export default {
  name: 'employee-registration-column',
  props: {
    selectedMemberId: {
      default: 'all'
    }
  },
  data() {
    return {
      columnChartOptions: {
        height: 600,
        width: '100%',
        chart: {
          title: 'Employee Registration by Job Type',
          subtitle:
            'Registered & Voted, Registered & Has Not Voted, Not Registered, and Ineligible'
        },
        legend: {
          position: 'bottom',
          width: '100%',
          alignment: 'start'
        },
        animation: {
          startup: true,
          duration: 500,
          easing: 'linear'
        },
        bar: {
          groupWidth: 100
        },
        axisTitlesPosition: 'out',
        chartArea: {
          height: 400,
          width: '80%',
          bottom: 150
        },
        colors: ['mediumblue', 'gold', 'firebrick', 'darkgreen'],
        fontSize: 14,
        fontName: 'roboto',
        hAxis: {
          baselineColor: '#5c8dd7',
          gridlines: {
            color: 'e6e6e6',
            count: 10
          },
          textPosition: 'out'
        }
      },
      columnChartData: [
        [
          'Company Role',
          'Registered & Voted',
          'Registered & Has Not Voted',
          'Not Registered',
          'Ineligible'
        ]
      ],
      loading: true,
      columnChart: []
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections,
      selectedElection: s => s.electionStore.selectedElection,
      selectedMember: s => s.adminStore.selectedMember
    }),
    ...mapGetters({
      user: 'user'
    }),
    isAdmin() {
      return this.user.id_rol === 1
    },
    memberId() {
      return this.user.profile_id.member_id
    },
    columnChartEventListener() {
      return {
        'select': () => {
          this.onChartClick()
        }
      }
    },
    hasStatistics() {
      return !!this.columnChart.length
    },
    navBtn() {
      return this.isAdmin
        ? {
          name: 'Reporting Map',
          nav: 'ReportingMaps'
        }
        : {
          name: 'Go to Voting',
          nav: 'Voting'
        }
    }
  },
  watch: {
    selectedMember: {
      async handler() {
        if (this.selectedElection.id) await this.getStatistics()
      },
      deep: true
    },
    selectedElection: {
      async handler() {
        if (this.selectedElection.id) await this.getStatistics()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      employeeRegistrationByAdmin: 'adminStore/employeeRegistrationByJobType',
      employeeRegistrationByMember: 'memberStore/employeeRegistrationByJobType',
      reportingFilters: 'reportingFilters'
    }),
    ...mapMutations({
      changeRoutePush: 'changeRoutePush'
    }),
    async getStatistics() {
      this.loading = true
      this.columnChartData = [
        [
          'Company Role',
          'Registered & Voted',
          'Registered & Has Not Voted',
          'Not Registered',
          'Ineligible'
        ]
      ]
      const params = {
        memberId: this.isAdmin ? this.selectedMemberId : this.memberId,
        electionId: this.selectedElection.id
      }
      this.columnChart = this.isAdmin
        ? await this.employeeRegistrationByAdmin(params)
        : await this.employeeRegistrationByMember(params)
      if (this.hasStatistics) {
        this.columnChartData = [
          [
            'Company Role',
            'Registered & Voted',
            'Registered & Has Not Voted',
            'Not Registered',
            'Ineligible'
          ]
        ]
        this.columnChart.forEach(column => this.columnChartData.push(column))
      }
      setTimeout(() => this.loading = false, 500)
    },
    async onChartClick() {
      const selected = this.$refs.columnChartObject.chartObject.getSelection()
      if (this.isAdmin) {
        if (selected.length > 0) {
          const selectedEmployeeRole = this.columnChartData[selected[0].column][0]
          const res = stringConverter.toReportingFilters(this.selectedElection, selectedEmployeeRole)
          this.changeRoutePush(true)
          await this.reportingFilters(res)
          await this.$router.push({ name: 'ReportingMaps' })
        }
      } else {
        if (selected.length === 0 || selected[0].row === null) {
          this.$emit('show-section-employees-info', { show: false, employeesByVoteStatus: [] })
          return
        }
        this.$emit('show-section-employees-info', { show: true, loading: true })
        const selectedJobType = this.columnChart[selected[0]?.row][0]
        const selectedVoteCategory = this.columnChartData[0][selected[0].column]
        this.sectionEmployeesByVoteTitle = `Employees who: ${ selectedJobType } - ${ selectedVoteCategory }`
        try {
          const response = await memberService.getEmployeesByVoteStatus({
            memberId: this.memberId,
            electionId: this.selectedElection.id,
            selectedJobType,
            selectedVoteCategory
          })
          if (response.length) {
            this.$emit('show-section-employees-info', {
              loading: false,
              sectionEmployeesByVoteTitle: this.sectionEmployeesByVoteTitle,
              show: true,
              employeesByVoteStatus: response
            })
          } else {
            this.$emit('show-section-employees-info', {
              loading: false,
              sectionEmployeesByVoteTitle: this.sectionEmployeesByVoteTitle,
              show: true,
              employeesByVoteStatus: response
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.wrap-skeleton-loader {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  width: 500px;
  transform: rotate(-90deg) !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  .v-skeleton-loader__text {
    margin: 6% 0;
    height: 25px !important;
    background: #5b8dd7 !important;
    opacity: 0.7;
  }
}
</style>
