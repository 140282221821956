<template>
  <v-card class='mx-3 my-5 pa-5' color='success'>
    <v-row class='pa-0' justify='center'>
      <v-card-title class='pa-3 white--text headline ma-0'>
        New employees added to MCBA by a member company.
      </v-card-title>
      <v-menu offset-y>
        <template #activator='{ on, attrs }'>
          <v-btn
            class='ma-2'
            depressed
            elevation='4'
            large
            v-bind='attrs'
            v-on='on'
          >
            Export
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for='timestamp in timestamps'
            :key='timestamp.value'
            @click='exportFile(timestamp.value)'
          >
            <v-list-item-title>{{ timestamp.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AdminNotificationAlert',
  data: () => ({
    timestamps: [
      { title: 'Past day', value: 1 },
      { title: 'Past week', value: 2 },
      { title: 'Past month', value: 3 }
    ]
  }),
  methods: {
    exportFile(timestamp) {
      location.href = `${ process.env.VUE_APP_BASE_URL }business/employees-added-by-member-company/${ timestamp }`
    }
  }
}
</script>

<style scoped>

</style>
