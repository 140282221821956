<template>
  <div v-frag>
    <admin-notification-alert/>

    <info-cards
      :allocation='allocation'
      :loading='loadingStatistics'
      :volunteering-statistics='volunteeringStatistics'
      :voting-statistics='votingStatistics'
    />

    <change-company
      :loading='loadingElections'
      :members='allMembers'
      :selected-member='selectedMember'
      :title='sections.change_company'
      class='mx-3 my-10'
    />

    <change-election
      :elections='adminElections'
      :loading='loadingElections'
      :selectedElection='selectedElection'
      :title='sections.change_election'
      class='mx-3 my-10'
    />

    <employee-registration-column
      :selected-member-id='memberId'
      class='my-10 mx-3'
    />

    <v-row class='mx-3'>
      <v-col md='7' class='pa-0 pr-md-7 mb-0 mb-sm-7'>
        <donation-statistics
          :loading='loadingStatistics'
          :statistics='donationStatistics'
        />
      </v-col>
      <v-col md='5' class='pa-0'>
        <voting-record-cycle
          :selected-member-id='memberId'
          class='mb-10'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AdminNotificationAlert from '../../components/AdminNotificationAlert'
import DonationStatistics from '@/components/donation-statistics'
import EmployeeRegistrationColumn from '@/components/charts/employee-registration-column'
import VotingRecordCycle from '@/components/charts/voting-record-cycle'
import InfoCards from '@/components/info-cards'
import ChangeElection from '@/components/change-election/change-election'
import ChangeCompany from '@/components/change-company/change-company'

export default {
  components: {
    ChangeCompany,
    ChangeElection,
    VotingRecordCycle,
    EmployeeRegistrationColumn,
    DonationStatistics,
    AdminNotificationAlert,
    InfoCards
  },
  data() {
    return {
      loadingStatistics: true,
      loadingElections: true,
      loadingMembers: false
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections,
      donationStatistics: s => s.adminStore.donationStatistics,
      votingStatistics: s => s.adminStore.votingStatistics,
      volunteeringStatistics: s => s.adminStore.volunteeringStatistics,
      members: s => s.adminStore.members,
      selectedMember: s => s.adminStore.selectedMember || {},
      selectedElection: s => s.electionStore.selectedElection,
      adminElections: s => s.electionStore.adminElections
    }),
    ...mapGetters({
      allocation: 'adminStore/allocation'
    }),
    allMembers() {
      return [{ id: 'all', name: 'All Companies' }, ...this.members]
    },
    isMemberSelected() {
      return this.selectedMember && !!Object.keys(this.selectedMember).length
    },
    memberId() {
      return this.selectedMember?.id
    }
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedMember?.id)
          await this.getStatistics()
      },
      deep: true
    },
    selectedMember: {
      async handler() {
        if (this.selectedElection?.id)
          await this.getStatistics()
      },
      deep: true
    }
  },
  async created() {
    await Promise.allSettled([
      this.getMembers(),
      this.getElections()
    ])
    this.changeRoutePush(false)
  },
  methods: {
    ...mapActions({
      getVotingStatistics: 'adminStore/getVotingStatistics',
      getVolunteeringStatistics: 'adminStore/getVolunteeringStatistics',
      getDonationStatistics: 'adminStore/getDonationStatistics',
      getAdminMembers: 'adminStore/getMembers',
      getAdminElections: 'electionStore/getAdminElections'
    }),
    ...mapMutations({
      SET_SELECTED_MEMBER: 'adminStore/SET_SELECTED_MEMBER',
      changeRoutePush: 'changeRoutePush'
    }),
    async getMembers() {
      this.loadingMembers = true
      await this.getAdminMembers()
      this.checkSelectedMember()
      this.loadingMembers = false
    },
    async getElections() {
      this.loadingElections = true
      await this.getAdminElections()
      this.loadingElections = false
    },
    async getStatistics() {
      this.loadingStatistics = true
      await Promise.allSettled([
        this.getVotingStatistics({ memberId: this.selectedMember.id, electionId: this.selectedElection.id }),
        this.getVolunteeringStatistics(this.selectedMember.id),
        this.getDonationStatistics()
      ])
      this.loadingStatistics = false
    },
    checkSelectedMember() {
      if (!this.isMemberSelected)
        this.SET_SELECTED_MEMBER(this.allMembers[0])
    }
  }
}
</script>

<style lang='scss' scoped>
.v-card__title {
  padding-bottom: 0;
  margin-bottom: 5px;
}
.v-card__subtitle {
  padding-bottom: 0;
  padding-top: 0;
}
.my-table.v-data-table :deep(th) {
  font-size: 16px !important;
  font-weight: bold;
  color: #444444 !important;
}
</style>
