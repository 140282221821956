<template>
  <div>
    <v-row
      v-if='!loading'
      class='d-flex flex-row flex-nowrap align-center justify-start pl-6'
    >
      <v-card
        v-if="isModuleActive('voting')"
        class='mt-8 d-flex flex-row'
        height='170px'
        min-width='32%'
      >
        <v-icon
          class='primary white--text px-2'
          size='60'
        >
          mdi-inbox-arrow-down
        </v-icon>
        <v-col class='px-0'>
          <v-card-subtitle>
            Voted in
            <span class='text-capitalize'>{{ selectedElection.description }}</span>
          </v-card-subtitle>
          <v-card-title
            v-if='votingStatistics.runoff'
            class='pt-1 mb-1 font-weight-bold'
          >
            {{ votingStatistics.votedInRunoff || '0' }}% Voted in Runoff Election
          </v-card-title>
          <div v-else>
            <!--            <v-card-title class='pt-1 mb-1 font-weight-bold'>-->
            <!--              {{ votingStatistics.employeesVoted || '0' }}% Voted - 123-->
            <!--            </v-card-title>-->
            <v-card-title class='pt-5 mb-2 font-weight-bold'>
              {{ votingStatistics.employeesInelegible || '0' }}% Eligible Voted
            </v-card-title>
            <v-card-title class='pt-2 pb-5 font-weight-bold'>
              {{ votingStatistics.employeesRegistered || '0' }}% Registered Voted
            </v-card-title>
          </div>
        </v-col>
      </v-card>
      <v-card
        v-if="isModuleActive('volunteering')"
        class='mt-8 d-flex flex-row ml-4'
        height='170px'
        min-width='32%'
      >
        <v-icon
          class='primary white--text px-2'
          size='60'
        >
          mdi-hand-heart-outline
        </v-icon>
        <v-col class='px-0'>
          <v-card-title class='mb-6 font-weight-bold'>
            <span class='mr-1'>
              {{ volunteeringStatistics.hoursVolunteered }}
            </span>
            hours volunteered
          </v-card-title>
          <v-card-subtitle>
            Texas Wide in {{ volunteeringStatistics.year }}
          </v-card-subtitle>
        </v-col>
      </v-card>
      <v-card
        v-if="isModuleActive('donations')"
        class='mt-8 d-flex flex-row ml-4'
        height='170px'
        min-width='32%'
      >
        <v-icon
          class='primary white--text px-2'
          size='60'
        >
          mdi-currency-usd
        </v-icon>
        <v-col class='px-0'>
          <v-card-title class='mb-6 font-weight-bold'>
            <span class='mr-1'>${{ allocationTotal }} </span>
            donated
          </v-card-title>
          <v-card-subtitle>
            Texas Wide in {{ allocationYear }}
          </v-card-subtitle>
        </v-col>
      </v-card>
    </v-row>
    <v-row
      v-else
      class='d-flex flex-row flex-nowrap align-center justify-space-between px-6'
    >
      <v-card
        v-for='i in 3'
        :key='i'
        class='mt-8 d-flex flex-row'
        height='170px'
        min-width='32%'
      >
        <v-skeleton-loader type='image, article'></v-skeleton-loader>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'info-cards',
  props: {
    votingStatistics: {
      type: Object,
      required: true
    },
    volunteeringStatistics: {
      type: Object,
      required: true
    },
    allocation: {
      required: true,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    isModuleActive(module) {
      return this.isAdmin ? true : this.getModules.includes(module)
    }
  },
  computed: {
    ...mapState({
      selectedElection: state => state.electionStore.selectedElection
    }),
    ...mapGetters({
      getModules: 'getModules'
    }),
    allocationTotal() {
      return this.allocation ? this.allocation.allocationTotal : 0
    },
    isAdmin() {
      return this.$store.getters.user.id_rol === 1
    },
    allocationYear() {
      return this.allocation ? this.allocation.year : ''
    }
  }
}
</script>

<style lang='scss' scoped>
.v-card__title {
  padding-bottom: 0;
  margin-bottom: 5px;
}
.v-card__subtitle {
  padding-bottom: 0;
  padding-top: 0;
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  display: flex;
  width: 100%;
  background: #5b8dd7 !important;
  opacity: 0.7;
  .v-skeleton-loader__image {
    width: 76px;
  }
  .v-skeleton-loader__article {
    width: calc(100% - 60px);
  }
  .v-skeleton-loader__heading, .v-skeleton-loader__text {
    background: #5b8dd7 !important;
  }
}
</style>
